// CarMovingBody.ts
// Based on https://www.asawicki.info/Mirror/Car%20Physics%20for%20Games/Car%20Physics%20for%20Games.html

import { MovingBody } from "./MovingBody";

export class CarMovingBody extends MovingBody {
  protected velocityX: number = 0; // Velocity along the x-axis
  protected velocityY: number = 0; // Velocity along the y-axis
  protected angularVelocity: number = 0; // Angular velocity (for rotation)
  protected readonly maxSpeed: number = 5; // Max linear speed
  protected readonly acceleration: number = 0.5; // Acceleration rate
  protected readonly brakingPower: number = 1; // Braking deceleration
  protected readonly turnSpeed: number = 0.07; // Turning rate
  protected readonly friction: number = 0.98; // Friction coefficient
  protected readonly stopSpeedThreshold = 0.13;
  protected readonly alignmentStrength = 0.0005;

  normalizeAngle(angle: number): number {
    while (angle > Math.PI) angle -= 2 * Math.PI;
    while (angle < -Math.PI) angle += 2 * Math.PI;
    return angle;
  }

  move(deltaTimeMultiplier: number, direction: { x: number; y: number }): void {
    const dt = deltaTimeMultiplier;

    // Forward/backward input: direction.y
    if (direction.y > 0) {
      // Accelerating forward
      const accelX = Math.cos(this._rotation) * this.acceleration * dt;
      const accelY = Math.sin(this._rotation) * this.acceleration * dt;
      this.velocityX += accelX;
      this.velocityY += accelY;
    } else if (direction.y < 0) {
      // Braking
      const brakeX = Math.cos(this._rotation) * this.brakingPower * dt;
      const brakeY = Math.sin(this._rotation) * this.brakingPower * dt;
      this.velocityX -= brakeX;
      this.velocityY -= brakeY;
    }

    // Friction
    this.velocityX *= this.friction;
    this.velocityY *= this.friction;

    // Limit max speed
    let speed = Math.sqrt(this.velocityX ** 2 + this.velocityY ** 2);
    if (speed <= this.stopSpeedThreshold) {
      speed = 0;
      this.velocityX = 0;
      this.velocityY = 0;
    } else if (speed > this.maxSpeed) {
      const scale = this.maxSpeed / speed;
      this.velocityX *= scale;
      this.velocityY *= scale;
      speed = this.maxSpeed; // Update speed to reflect capped velocity
    }

    // Turning input: direction.x
    if (speed > 0) {
      // Always allow the car to turn as long as it is moving
      this.angularVelocity =
        direction.x * this.turnSpeed * (speed / this.maxSpeed) * dt;
      this._rotation += this.angularVelocity;

      // Recalculate velocity direction based on new rotation
      const velocityAngle = Math.atan2(this.velocityY, this.velocityX); // Current velocity direction
      const newDirection = velocityAngle + this.angularVelocity; // Adjust by angular velocity
      const newVelocityX = speed * Math.cos(newDirection);
      const newVelocityY = speed * Math.sin(newDirection);
      this.velocityX = newVelocityX;
      this.velocityY = newVelocityY;
    }

    // Update position based on velocity
    this.x += this.velocityX * dt;
    this.y += this.velocityY * dt;

    // Update shapes
    this.shapes.forEach((shape) => {
      shape.x = this.x;
      shape.y = this.y;
      shape.rotation = this.rotation;
    });
  }
}
