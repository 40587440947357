// function ItchShowcase() {
//   return (
//     <div id="itch-showcase" style={{}}>
//       <a href="https://bates550.itch.io/sharon-goes-to-charon">
//         Sharon Goes to Charon
//       </a>
//     </div>
//   );
// }

// export default ItchShowcase;

// import useWindowDimensions from '../hooks/useWindowDimensions'
import Carousel from "better-react-carousel";

const projects = [
  {
    title: "Sharon Goes to Charon",
    imgRef: require("../../projects/sharon-goes-to-charon/splash.gif"),
    description: "Nokia 3310 Game Jam project created in PixiJS.",
    link: "https://bates550.itch.io/sharon-goes-to-charon",
  },
];

function Project({
  title,
  imgRef,
  description,
  link,
  imgAlt = "Project image",
}) {
  return (
    <div
      style={{
        textAlign: "center",
        // width: "100%",
      }}
    >
      <a
        href={link}
        style={{
          textDecoration: "none",
          color: "inherit",
          // cursor: "auto",
        }}
      >
        <img
          src={imgRef}
          alt={imgAlt}
          style={{
            width: "30%",
          }}
        />
        <h4
          style={{
            margin: "0",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          {title}
        </h4>
      </a>
      {description}
    </div>
  );
}

function ProjectsCarousel() {
  // const { height, width } = useWindowDimensions();
  return (
    <div id="projects-container" className="bgc-ddd w-95 m-c pb-1">
      <h3 className="ta-c m-0 pb-05">Projects</h3>
      <Carousel
        cols={1}
        rows={1}
        gap="10%"
        loop
        mobileBreakpoint={654}
        scrollSnap={true}
      >
        {projects.map((project, i) => (
          <Carousel.Item
            key={i}
            style={{
              position: "relative",
            }}
          >
            <Project key={i} {...project} />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default ProjectsCarousel;
