// WebsiteGame.tsx

import { useEffect, useState } from "react";
import { useCanvas } from "../Utilities/CanvasProvider";
import { create, destroy, gameDimensions, render, update } from "./Game";
import { Coordinates, Dimensions, KeyDirections } from "./Types";

const WebsiteGame = () => {
  const { canvas, ctx } = useCanvas();

  const [keyDirections, setKeyDirections] = useState({
    up: false,
    down: false,
    left: false,
    right: false,
  });
  const [isFocused, setIsFocused] = useState(true); // Track focus state

  useEffect(() => {
    // Handle keydown and keyup events for movement
    const handleKeyDown = (event: KeyboardEvent) => {
      event.stopPropagation();
      setKeyDirections((prev) => {
        switch (event.key) {
          case "ArrowUp":
          case "w":
            return { ...prev, up: true };
          case "ArrowDown":
          case "s":
            return { ...prev, down: true };
          case "ArrowLeft":
          case "a":
            return { ...prev, left: true };
          case "ArrowRight":
          case "d":
            return { ...prev, right: true };
          default:
            return prev;
        }
      });
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      event.stopPropagation();
      setKeyDirections((prev) => {
        switch (event.key) {
          case "ArrowUp":
          case "w":
            return { ...prev, up: false };
          case "ArrowDown":
          case "s":
            return { ...prev, down: false };
          case "ArrowLeft":
          case "a":
            return { ...prev, left: false };
          case "ArrowRight":
          case "d":
            return { ...prev, right: false };
          default:
            return prev;
        }
      });
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!ctx || !canvas || !isFocused) return; // Pause when not focused
    let previousTime = performance.now();
    // const updateWrapper = (currentTime: number, direction: Direction) => {
    const updateWrapper = (
      currentTime: number,
      keyDirection: KeyDirections
    ) => {
      const deltaTime = currentTime - previousTime;
      // update(deltaTime, direction);
      update(deltaTime, keyDirections);
      previousTime = currentTime;
    };
    const widthRatio = canvas.width / gameDimensions.width;
    const heightRatio = canvas.height / gameDimensions.height;
    const scale = Math.max(0.25, Math.min(widthRatio, heightRatio));
    const gameSize: Dimensions = {
      width: scale * gameDimensions.width,
      height: scale * gameDimensions.height,
    };
    const offset: Coordinates = {
      x: (canvas.width - gameSize.width) / 2,
      y: (canvas.height - gameSize.height) / 2,
    };

    let animationFrameId: number;
    const animate = (currentTime: number) => {
      if (isFocused) {
        updateWrapper(currentTime, keyDirections);
        render(ctx, canvas, scale, offset);
      }
      animationFrameId = requestAnimationFrame(animate);
    };
    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [ctx, canvas, keyDirections, isFocused]);

  useEffect(() => {
    if (!canvas) return;
    create();
    const canvasElement = document.querySelector("canvas");
    if (!canvasElement) return;
    const handleFocus = () => {
      setIsFocused(true);
      // setTimeout(() => {
      document.querySelector("canvas")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      document.body.style.overflow = "hidden"; // Disable scrolling
      // }, 250);
    };

    const handleBlur = () => {
      setIsFocused(false);
      document.body.style.overflow = ""; // Re-enable scrolling
    };

    canvasElement.addEventListener("focus", handleFocus);
    canvasElement.addEventListener("blur", handleBlur);
    // setTimeout(() => canvasElement.focus(), 250);
    return () => {
      canvasElement.removeEventListener("focus", handleFocus);
      canvasElement.removeEventListener("blur", handleBlur);
      destroy();
    };
  }, [canvas]);

  return null; // Make the canvas focusable
};

export default WebsiteGame;
